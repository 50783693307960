import React from 'react';

const LegalInfo = () => (
  <div className="legal-info">
    <p className="legal-info__title">Юридическое лицо</p>
    <table className="legal-info__table">
      <tbody>
        <tr className="legal-info__row legal-info__row--new_block">
          <td className="legal-info__cell">Полное наименование организации</td>
          <td className="legal-info_cell">
            Общество с ограниченной ответственностью «Система КЭСПА»
          </td>
        </tr>
        <tr className="legal-info__row">
          <td className="legal-info__cell">Юридический адрес организации</td>
          <td className="legal-info_cell">
            115191, Россия, Москва, Холодильный переулок, 3, к. 3, стр. 13,
            1/III/6А
          </td>
        </tr>
        <tr className="legal-info__row legal-info__row--new_block">
          <td className="legal-info__cell">ИНН</td>
          <td className="legal-info_cell">7726467800</td>
        </tr>
        <tr className="legal-info__row">
          <td className="legal-info__cell">ОГРН</td>
          <td className="legal-info_cell">1207700322488</td>
        </tr>
        <tr className="legal-info__row legal-info__row--new_block">
          <td className="legal-info__cell">Банк</td>
          <td className="legal-info_cell">АО «АЛЬФА-БАНК»</td>
        </tr>
        <tr className="legal-info__row">
          <td className="legal-info__cell">Расчетный счет</td>
          <td className="legal-info_cell">40702810702860010180</td>
        </tr>

        <tr className="legal-info__row">
          <td className="legal-info__cell">Юридический адрес банка</td>
          <td className="legal-info_cell">
            107078, г. Москва, ул. Каланчевская, д. 27
          </td>
        </tr>
        <tr className="legal-info__row">
          <td className="legal-info__cell">Корр.счет банка</td>
          <td className="legal-info_cell">30101810200000000593</td>
        </tr>
        <tr className="legal-info__row">
          <td className="legal-info__cell">БИК банка</td>
          <td className="legal-info_cell">044525593</td>
        </tr>
      </tbody>
    </table>
  </div>
);

export default LegalInfo;
