import React from 'react';
import Helmet from 'react-helmet';
import wrapInSection from '../components/wrapInSection';
import wrapInLayout from '../components/wrapInLayout';
import LegalInfo from '../components/LegalInfo';

const Offer = () => (
  <div className="offer-wrapper page-wrapper narrow-container">
    <Helmet title="КЭСПА – Оферта" />
    <h1>Оферта</h1>
    <h2>Договор публичной оферты об оказании образовательных услуг</h2>
    <p>
      <b>г. Москва, 05 сентября 2020 года</b>
    </p>
    <p>
      Общество с ограниченной ответственностью «Система КЭСПА» (ОГРНИП:
      1207700322488), именуемый в дальнейшем Исполнитель (далее по тексту
      «Исполнитель»), адресует настоящий Договор (далее Договор) об оказании
      платных образовательных услуг на проведение дистанционных занятий по
      изучению английского языка любому физическому лицу и (или) юридическому
      лицу, выразившему готовность воспользоваться Услугами Исполнителя (далее
      по тексту «Слушатель»).
    </p>

    <div>
      <ol className="marked">
        <li>
          <h3>Акцепт оферты</h3>
          <ol>
            <li>
              Текст настоящего Договора является публичной офертой (в
              соответствии со статьей 435 и частью 2 статьи 437 Гражданского
              кодекса РФ).
            </li>
            <li>
              Акцепт (принятие) оферты — оплата заказанных услуг путем внесения
              предоплаты в порядке, определяемом настоящим Договором, и
              использование Услуг Исполнителя.
            </li>

            <li>
              Акцепт Слушателем настоящего Договора означает, что он полностью
              согласен со всеми положениями настоящего Договора.
            </li>

            <li>
              С момента акцепта Слушателем настоящий Договор считается
              заключенным. В подтверждение заключения настоящего Договора и по
              ходу его исполнения Слушателю приходят информационные письма на
              электронную почту, указанную Слушателем Исполнителю при
              регистрации.
            </li>

            <li>
              Настоящий Договор вступает в силу с момента его заключения и
              действует до исполнения Сторонами своих обязательств по нему, если
              иное не предусмотрено настоящим Договором и законодательством РФ.
            </li>

            <li>
              Отношения в области защиты прав потребителей регулируются
              Гражданским кодексом РФ, Законом “О защите прав потребителей” и
              принимаемыми в соответствии с ним иными федеральными законами и
              правовыми актами Российской Федерации.
            </li>
          </ol>
        </li>
        <li>
          <h3>Предмет договора</h3>
          <ol>
            <li>
              В соответствии с настоящим договором Исполнитель обязуется
              оказывать Слушателю услуги по проведению дистанционных занятий по
              английскому языку, посредством интернет-платформы{' '}
              <a href="https://kespa.ru/">kespa.ru</a> (далее по тексту
              «Обучающая Платформа») и программы Skype, а Слушатель обязуется
              принимать эти услуги и оплачивать их в соответствии с условиями,
              предусмотренными настоящим Договором.
            </li>
            <li>
              Количество занятий, проводимых в соответствии с настоящим
              Договором, определяется количеством оплаченных Слушателем занятий.
            </li>
            <li>
              Методику и формат дистанционного обучения определяет Исполнитель,
              учитывая пожелания Слушателя, высказанные перед осуществлением
              оплаты Услуг, а также в ходе обучения.
            </li>
          </ol>
        </li>
        <li>
          <h3>Права и обязанности сторон</h3>
          <ol>
            <li>
              Исполнитель обязуется:
              <ol>
                <li>
                  В согласованные Сторонами сроки оказать Услуги Слушателю
                  надлежащим образом в соответствии с условиями настоящего
                  Договора.
                </li>
                <li>
                  Не разглашать конфиденциальную информацию и данные,
                  предоставленные Слушателем в связи с исполнением настоящего
                  Договора, не раскрывать и не разглашать такие факты или такую
                  информацию (кроме информации общедоступного характера)
                  какой-либо третьей стороне без предварительного письменного
                  соглашения Слушателя.
                </li>
              </ol>
            </li>
            <li>
              Исполнитель вправе:
              <ol>
                <li>
                  Определять формы и методы организации учебного процесса.
                </li>
                <li>
                  Пользоваться услугами любых физических и юридических лиц
                  (далее по тексту «Преподаватели»), в целях своевременного и
                  качественного исполнения обязательств по Договору.
                </li>
                <li>
                  Требовать от Слушателя своевременной оплаты стоимости Услуг в
                  соответствии с условиями настоящего Договора.
                </li>
                <li>
                  В течение всего периода обучения Исполнитель оставляет за
                  собой право на замену Преподавателя и изменения в расписании
                  занятий.
                </li>
                <li>
                  Исполнитель вправе по своей инициативе перенести одно или
                  несколько занятий курса на другой день, проинформировав при
                  этом Слушателя не менее чем за один календарный день до даты
                  начала переносимого занятия.
                </li>
                <li>
                  В течение нерабочих праздничных дней в соответствии с трудовым
                  законодательством РФ, Исполнитель оставляет за собой право на
                  перенос занятий на следующие за праздниками рабочие дни.
                </li>
                <li>
                  Вести аудио- или видеозапись занятий с целью контроля качества
                  процесса обучения.
                </li>
                <li>
                  Приостанавливать работу Обучающей Платформы с целью проведения
                  технических работ, предварительно уведомив об этом Слушателя
                  не позднее чем за 24 часа до проведения соответствующих работ.
                </li>
                <li>
                  Использовать контактные данные, указанные Слушателем при
                  регистрации, для отправки информационных и
                  рекламно-информационных материалов, направленных с целью
                  информирования Клиента о деятельности Исполнителя и ходе
                  исполнения Договора. При отсутствии письменного или устного
                  отказа Клиента в получении данных материалов, направленного в
                  адрес Исполнителя, согласие на получение данного рода
                  материалов считается подтвержденным.
                </li>
                <li>
                  Отказать Слушателю в предоставлении и продлении услуг без
                  объяснения причин отказа.
                </li>
              </ol>
            </li>
            <li>
              Слушатель обязуется:
              <ol>
                <li>
                  Предоставить Исполнителю все сведения и данные, необходимые
                  для выполнения своих обязательств по настоящему Договору. В
                  эти данные входят:
                  <ul>
                    <li>
                      контактные данные, запрашиваемые Обучающей Платформой при
                      регистрации;
                    </li>
                    <li>
                      персональные данные, запрашиваемые Преподавателем при
                      проведении занятий.
                    </li>
                  </ul>
                </li>
                <li>
                  Производить оплату за обучение в соответствии с настоящим
                  Договором.
                </li>
                <li>
                  Не разглашать конфиденциальную информацию и иные данные,
                  предоставленные Исполнителем в связи с исполнением настоящего
                  Договора, не раскрывать и не разглашать такие факты или
                  информацию (кроме информации общедоступного характера)
                  какой-либо третьей стороне без предварительного письменного
                  согласия Исполнителя.
                </li>
              </ol>
            </li>

            <li>
              Слушатель вправе:
              <ol>
                <li>
                  Требовать от Исполнителя выполнения его обязательств по
                  настоящему Договору в срок и с надлежащим качеством. Занятие
                  считается проведенным надлежащим образом, если в течение 1
                  (одного) часа с момента его проведения Слушатель не сообщит
                  Исполнителю о том, что оно не состоялось по вине Преподавателя
                  или Исполнителя.
                </li>
                <li>
                  Обращаться к Исполнителю по вопросам, касающимся процесса
                  обучения, получать достоверную информацию об оценке своих
                  знаний.
                </li>
                <li>
                  Отправить Исполнителю запрос на замену Преподавателя, сообщив
                  об этом Исполнителю за 72 часа до установленной даты
                  следующего занятия и аргументировав свой запрос в письменной
                  или устной форме.
                </li>
                <li>
                  Отказаться от выполнения условий настоящего Договора в случае,
                  если Исполнитель не приступил к исполнению обязательств по
                  настоящему Договору.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h3>Перенос занятий и неявка на занятия</h3>
          <ol>
            <li>
              Перенос или отмена занятий Слушателем могут быть осуществлены не
              позднее 8 (Восьми) часов до начала занятия. Несоблюдение
              указанного срока приравнивается к неявке Слушателя на занятие и
              оплачивается по полной стоимости. Количество возможных переносов и
              отмен занятий зависит от интенсивности графика занятий Слушателя.
              Слушатель имеет право на:
            </li>
            <ul>
              <li>
                перенос или отмену 1 (Одного) занятия в месяц при графике
                занятий 1 (Одно) занятие в неделю;
              </li>
              <li>
                перенос или отмену 2 (Двух) занятий в месяц при графике занятий
                2 (Два) и 3 (Три) занятия в неделю;
              </li>
              <li>
                перенос или отмену 4 (Четырех) занятий в месяц при графике
                занятий 4 (Четыре) и более занятия в неделю.
              </li>
            </ul>
            <li>
              Преподаватель имеет право переносить занятия (в случае болезни и
              по другим уважительным причинам), предупредив об этом Слушателя не
              менее, чем за 24 часа до назначенного времени занятия. Если
              Слушатель считает, что Преподаватель слишком часто переносит
              занятия или несвоевременно предупреждает о переносах, он вправе
              обратиться к Исполнителю с требованием о замене Преподавателя.
            </li>

            <li>
              Если в установленное время Слушатель недоступен для звонка
              Преподавателя, Преподаватель повторяет попытки связаться со
              Слушателем (не менее трех раз). При этом временем начала занятия
              считается установленное в графике занятий время занятия. Если в
              результате вышеназванных процедур связаться со Слушателем не
              удается, занятие считается проведенным и оплачивается по полной
              стоимости.
            </li>

            <li>
              Если в установленное время начала занятий плюс 5 (Пять) минут
              Слушатель не получает звонка от Преподавателя, он обязан
              немедленно связаться с Исполнителем. Занятия, которые не
              состоялись по вине Преподавателя, переносятся в полном объеме на
              другое удобное для Исполнителя время.
            </li>
          </ol>
        </li>

        <li>
          <h3>Приостановка занятий</h3>
          <ol>
            <li>
              Слушатель может приостановить оказание Услуг путем направления
              Исполнителю устного или письменного запроса не позднее 2-ух суток
              с даты последнего занятия перед перерывом. При этом Слушатель
              должен сообщить Исполнителю точную дату и время последнего занятия
              перед перерывом, а также ориентировочную дату первого занятия
              после перерыва.
            </li>
            <li>
              Исполнитель вправе приостановить оказание услуг по собственной
              инициативе в дни новогодних, рождественских и майских праздников,
              предварительно предупредив об этом Слушателя путем направления
              сообщений по электронной почте или телефонного звонка.
            </li>
          </ol>
        </li>
        <li>
          <h3>Отказ от продолжения занятий</h3>
          <ol>
            <li>
              Если настоящий Договор расторгается по желанию Слушателя, он
              должен предоставить Исполнителю мотивированный отказ от услуг
              Исполнителя. В этом случае Исполнитель возвращает Слушателю
              денежные средства в размере, равном сумме не исполненных
              Исполнителем обязательств за удержанием 25% от исходной суммы,
              уплаченной Слушателем по договору.
            </li>
            <li>
              В случае расторжения Договора по совместной инициативе или
              инициативе одной из сторон срок возврата денежных средств
              составляет 30 календарных дней.
            </li>
            <li>
              Преподаватель Слушателя может быть заменен при наличии для этого
              уважительных причин (временная нетрудоспособность Преподавателя,
              другие обстоятельства), о чем Исполнитель сообщает Слушателю по
              указанным контактным данным. В случае, если Слушатель не захочет
              заниматься с другим Преподавателем, Слушатель вправе приостановить
              оказание занятий в порядке, предусмотренном настоящим Договором.
            </li>
          </ol>
        </li>
        <li>
          <h3>Порядок направления сообщений</h3>
          <ol>
            <li>
              Исполнитель и Преподаватели могут использовать любые контактные
              данные Слушателя, из указанных при регистрации, для направления
              ему информационных сообщений, в том числе об отмене и переносе
              занятий.
            </li>
            <li>
              Для связи с Исполнителем Слушатель должен использовать контактные
              данные Исполнителя, указанные на странице{' '}
              <a href="https://kespa.ru/contacts">https://kespa.ru/contacts</a>
            </li>
            <li>
              Для связи со своим Преподавателем Слушатель должен использовать
              контактные данные Преподавателя, указанные в профиле Преподавателя
              на соответствующей странице Обучающей Платформы.
            </li>
            <li>
              Сообщения по телефону принимаются в рабочие часы (по будням с
              10:00 до 21:00 по московскому времени). Отправленные в другое
              время сообщения о переносе занятий считаются непринятыми.
            </li>
          </ol>
        </li>
        <li>
          <h3>Стоимость услуг и порядок расчетов</h3>
          <ol>
            <li>
              Стоимость типовых услуг Исполнителя публикуются Исполнителем на
              сайте по адресу <a href="https://kespa.ru">https://kespa.ru</a>
            </li>
            <li>
              Исполнитель может в одностороннем порядке изменять стоимость
              типовых услуг путем размещения информации на сайте Исполнителя по
              адресу <a href="https://kespa.ru">https://kespa.ru</a>. При этом
              стоимость уже оплаченных Слушателем занятий не подлежит изменению.
            </li>
            <li>
              Исполнитель по устному соглашению со Слушателем имеет право
              изменить стоимость оказываемых услуг в том случае, когда Слушатель
              в устной или письменной форме направил Исполнителю запрос на
              обеспечение специальных условий обучения (нетипичная частота,
              длительность или время занятий, прохождение обучения у
              определенного преподавателя).
            </li>

            <li>
              Оплата Услуг осуществляется на основе стопроцентной предоплаты.
              Оплата производится банковской картой через форму платежной
              системы в личном кабинете Слушателя на сайте Обучающей Платформы.
            </li>

            <li>
              Если у Слушателя закончились оплаченные занятия, для продолжения
              обучения он обязан оплатить следующие занятия до момента начала
              следующего занятия.
            </li>

            <li>
              В случае наличия у Слушателя задолженности по оплате занятий в
              количестве более 1 занятия и более, Исполнитель вправе прекратить
              оказание Услуг и потребовать Слушателя возместить денежные
              средства, равные размеру задолженности.
            </li>

            <li>
              Моментом оплаты считается поступление денежных средств на счет
              Исполнителя.
            </li>

            <li>
              Слушатель самостоятельно несет ответственность за правильность
              производимых им платежей.
            </li>

            <li>
              Слушатель самостоятельно оплачивает все услуги организаций связи,
              необходимые для потребления им Услуг Исполнителя.
            </li>
          </ol>
        </li>

        <li>
          <h3>Системные требования</h3>
          <ol>
            <li>
              Исполнитель не отвечает за неоказание услуги в связи с отсутствием
              у Слушателя необходимого программного обеспечения или технических
              проблем с Интернетом.
            </li>

            <li>
              Минимальные системные требования:
              <ul>
                <li>
                  Оперативная память компьютера: 2 Гб и выше, процессор: 1
                  ядерный процессор от 1.8 ГГц;
                </li>
                <li>
                  Операционная система: Windows 7/8/8.1/10, Mac OS X
                  10.9/10.10/10.11, Ubuntu Linux 14.04/16.04;
                </li>
                <li>
                  Установленный браузер Google Chrome последней стабильной
                  пользовательской версии с включённым авто­обновлением;
                </li>
                <li>
                  Установленная программа Skype последней стабильной версии;
                </li>
                <li>Наличие видеокамеры с разрешением 1 Мп и выше;</li>
                <li>Наличие микрофона;</li>
                <li>Интернет ­соединение от 10 Мбит/сек.</li>
              </ul>
            </li>
          </ol>
        </li>
        <li>
          <h3>Ответственность сторон</h3>

          <ol>
            <li>
              В случае неисполнения или ненадлежащего выполнения Исполнителем
              обязанностей по настоящему Договору Слушатель вправе предъявить
              Исполнителю требование о возврате уплаченных им денежных средств в
              размере, равном сумме не исполненных Исполнителем обязательств, за
              удержанием суммы комиссий перевода денежных средств.
            </li>
          </ol>
        </li>
        <li>
          <h3>Прочие условия</h3>
          <ol>
            <li>
              Услуги, оказываемые в соответствии с настоящим Договором, являются
              образовательными. Все занятия проходят с использованием
              интернет-платформы <a href="https://kespa.ru/">kespa.ru</a> и
              программы Skype.
            </li>

            <li>
              За невыполнение или ненадлежащее выполнение обязательств по
              настоящему Договору Стороны несут ответственность в соответствии с
              условиями настоящего Договора и законодательством Российской
              Федерации.
            </li>

            <li>
              Стороны не несут ответственности за полное или частичное
              неисполнение ими своих обязательств по настоящему Договору, если
              такое неисполнение явилось следствием обстоятельств непреодолимой
              силы (форс-мажор). Надлежащим подтверждением наличия форс-мажорных
              обстоятельств и их продолжительности для Сторон является справка,
              выдаваемая уполномоченными органами.
            </li>

            <li>
              Все споры или разногласия, возникающие между Сторонами по
              настоящему Договору или в связи с ним, разрешаются путем
              переговоров между ними. Сторона, чье право нарушено, направляет
              другой Стороне письменную претензию (требование) посредством
              электронной связи. Если в течение 15 (Пятнадцати) календарных дней
              Сторона, направившая претензию, не получила ответ на нее, а также,
              если в течение 15 (Пятнадцати) календарных дней с момента
              получения первого ответа на претензию Стороны не пришли к
              каким-либо договоренностям, то Сторона, чье право нарушено, вправе
              для защиты своего права обратиться в суд в порядке, установленном
              законодательством РФ.
            </li>
          </ol>
        </li>
      </ol>

      <h2>Реквизиты исполнителя</h2>
      <LegalInfo />
    </div>
  </div>
);

export default wrapInLayout(wrapInSection(Offer));
